.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box {
  max-width: 340px;
  margin: 50px auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box__logo {
  margin-bottom: 1em;
}
.box .title {
  font-size: 33px;
  margin-bottom: 30px;
}
.box .error {
  color: red;
  display: flex;
  justify-content: center;
}
.box form {
  width: 100%;
}
.box form div {
  width: 100%;
  margin-bottom: 30px;
}
.box form button {
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
  border: 0;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  padding: 10px 0;
  text-transform: uppercase;
}
.box form button:hover {
  background: #fff;
  border: 1px solid #99a2ae;
  color: #3f4142;
  cursor: pointer;
}
.box form button:focus {
  outline: 0;
}
.box input {
  border: 0;
  box-shadow: 0 1px 0 0;
  width: 100%;
  background: rgba(76, 112, 239, 0);
}
.box input:focus {
  box-shadow: 0 1px 0 0 blue;
  outline: 0;
}
.links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: transparent;
}
.links a {
  font-size: 12px;
  color: #393e44;
  letter-spacing: 0;
  text-align: center;
  text-decoration: underline;
}
.links a:hover {
  text-decoration: none;
}

.google-sign-in {
  width: 100%;
  text-align: center;
}


.reactPivot {
  margin-top: 40px;
  padding: 10px 20px 20px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.reactPivot-soloDisplay {
  padding: 5px;
}

.reactPivot-clearSolo {
  opacity: 0.5;
  cursor: pointer;
  font-size: 120%;
  margin-right: 2px;
}
.reactPivot-clearSolo:hover {
  font-weight: bold;
}

.reactPivot select {
  color: #555;
  height: 28px;
  border: none;
  margin-right: 5px;
  margin-top: 5px;
  background-color: #FFF;
  border: 1px solid #CCC;
}

.reactPivot-results {
  padding-bottom: .5rem;
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  -webkit-scrollbar-width: thin;
  -webkit-scrollbar-color: #888 #f1f1f1;
}

.reactPivot-results::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.reactPivot-results::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.reactPivot-results::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.reactPivot-results table {
  width: 100%;
  clear: both;
  text-align: left;
  border-spacing: 0;
}

.reactPivot-results th.asc:after,
.reactPivot-results th.desc:after {
  font-size: 50%;
  opacity: 0.5;
}

.reactPivot-results th.asc:after { content: ' ▲' }
.reactPivot-results th.desc:after { content: ' ▼' }

.reactPivot-results td {
  border-top: 1px solid #ddd;
  padding: 8px;
}

.reactPivot-results td.reactPivot-indent {
  border: none;
}

.reactPivot-results tr:hover td {
  background: #f5f5f5
}

.reactPivot-results tr:hover td.reactPivot-indent {
  background: none;
}

.reactPivot-solo {opacity: 0}
.reactPivot-solo:hover {font-weight: bold}
td:hover .reactPivot-solo {opacity: 0.5}

.reactPivot-csvExport,
.reactPivot-columnControl {
  margin-left: auto;
}

.reactPivot-csvExport button {
  background-color: #FFF;
  border: 1px solid #CCC;
  height: 28px;
  color: #555;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 4px;
  margin-top: 5px;
}

.reactPivot-dimensions {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.reactPivot-calculation {
  text-align: right;
  font-family: monospace;
}

.reactPivot-hideColumn { opacity: 0 }

th:hover .reactPivot-hideColumn {
  opacity: 0.5;
  margin-right: 4px;
  margin-bottom: 2px;
}

.reactPivot-hideColumn:hover {
  font-weight: bold;
  cursor: pointer;
}

.reactPivot-pageNumber {
  padding: 2px;
  margin: 4px;
  cursor: pointer;
  color: gray;
  font-size: 14px;
}

.reactPivot-pageNumber:hover {
  font-weight: bold;
  border-bottom: black solid 1px;
  color: black;
}

.reactPivot-pageNumber.is-selected {
  font-weight: bold;
  border-bottom: black solid 1px;
  color: black;
}

.reactPivot-paginate {
  margin-top: 24px;
}

.tr {
  text-align: right;
}

.monospace {
  font-family: monospace;
}

.reactPivot-results th {
  text-align: center;
  font-size: 75%;
}